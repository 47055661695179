import bancai from '@/assets/default/bancai.jpg' // 板材
import guancai from '@/assets/default/guanguan.jpg' // 管材
import xingcai from '@/assets/default/xingcai.jpg' // 型材
import bangcai from '@/assets/default/bancai.jpg' // 棒材
import xiancai from '@/assets/default/bancai.jpg' // 线材
import zhipin from '@/assets/default/zhipin.jpg' // 制品


const nameArr = ['管', '板', '型', '棒', '线', '制']
const imgArr = [guancai, bancai, xingcai, bangcai, xiancai, zhipin]
export default function filterProductImg(name) {
    let index = nameArr.findIndex(v => {
        return name.includes(v)
    })
    console.log(index);
    return imgArr[index]
}